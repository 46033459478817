<template>
    <section class="blockElement space">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12">
                    <h1 class="bold mb-md-4 f-30">{{$t('sitemap.sitemaptitle')}} </h1>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-3 col-12 mb-3 mb-md-0">
                            <div class="card-body vueCard sitemap p-3">
                                <h3>{{$t('sitemap.sitemapcontent1')}} </h3>
                                <ul class="m-0 p-0">
                                    <li class="pt-1 mb-2"><router-link to="/login" class="medium f-14 mb-0">{{$t('sitemap.sitemapcontent2')}} </router-link></li>
                                    <li class="pt-1 mb-2"><a :href="static_vars.domainURL+'register/'" class="medium f-14 mb-0">{{$t('sitemap.sitemapcontent3')}} </a></li>
                                    <li class="pt-1 mb-2"><a :href="static_vars.domainURL+'register/forgot-password'" class="medium f-14 mb-0">{{$t('sitemap.sitemapcontent4')}} </a></li>
                                    <li class="pt-1 mb-2"><router-link to="/about" class="medium f-14 mb-0">{{$t('sitemap.sitemapcontent5')}} </router-link></li>
                                    <li class="pt-1 mb-2"><router-link to="/blog/category/knowledge-crunch" class="medium f-14 mb-0">{{$t('sitemap.sitemapcontent6')}} </router-link></li>
                                    <li class="pt-1 mb-2"><router-link to="/blog/category/trading-floor" class="medium f-14 mb-0">{{$t('sitemap.sitemapcontent7')}} </router-link></li>
                                    <li class="pt-1 mb-2"><router-link to="/blog/category/new-features" class="medium f-14 mb-0">{{$t('sitemap.sitemapcontent8')}} </router-link></li>
                                    <li class="pt-1 mb-2"><router-link to="/blog/category/market-view" class="medium f-14 mb-0">{{$t('sitemap.sitemapcontent9')}}</router-link></li>
                                    <!-- <li class="pt-1 mb-2"><router-link to="/become-an-affiliate" class="medium f-14 mb-0">{{$t('sitemap.sitemapcontent10')}}</router-link></li> -->
                                    <!-- <li class="pt-1 mb-2"><router-link to="/affiliate-guide" class="medium f-14 mb-0">{{$t('sitemap.sitemapcontent11')}} </router-link></li> -->
                                    <li class="pt-1 mb-2"><router-link to="/introducing-zulutrade" class="medium f-14 mb-0">{{$t('sitemap.sitemapcontent12')}} </router-link></li>
                                    <li class="pt-1 mb-2"><router-link to="/trading-tools/margin-calculator" class="medium f-14 mb-0">{{$t('sitemap.sitemapcontent13')}} </router-link></li>
                                    <li class="pt-1 mb-2"><router-link to="/trading-tools/profit-calculator" class="medium f-14 mb-0">{{$t('sitemap.sitemapcontent15')}} </router-link></li>
                                    <li class="pt-1 mb-2"><router-link to="/trading-tools/currency-converter" class="medium f-14 mb-0">{{$t('sitemap.sitemapcontent16')}}</router-link></li>
                                    <li class="pt-1 mb-2"><router-link to="/trading-tools/pip-calculator" class="medium f-14 mb-0">{{$t('sitemap.sitemapcontent17')}} </router-link></li>
                                    <li class="pt-1 mb-2"><router-link to="/help-center" class="medium f-14 mb-0">{{$t('sitemap.sitemapcontent18')}} </router-link></li>
                                    <!-- <li class="pt-1 mb-2"><router-link to="/pricing" class="medium f-14 mb-0">{{$t('sitemap.sitemapcontent19')}} </router-link></li> -->
                                    <li class="pt-1 mb-2"><router-link to="/markets" class="medium f-14 mb-0">{{$t('sitemap.sitemapcontent20')}} </router-link></li>
                                    <li class="pt-1 mb-2"><router-link to="/calendar" class="medium f-14 mb-0">{{$t('sitemap.sitemapcontent21')}} </router-link></li>
                                    <!-- <li class="pt-1 mb-2"><router-link to="/automated-trading" class="medium f-14 mb-0">{{$t('sitemap.sitemapcontent22')}} </router-link></li> -->
                                    <li class="pt-1 mb-2"><router-link to="/autoprotect-your-account" class="medium f-14 mb-0">{{$t('sitemap.sitemapcontent23')}} </router-link></li>
                                    <li class="pt-1 mb-2"><router-link to="/leaders" class="medium f-14 mb-0">{{$t('sitemap.sitemapcontent24')}} </router-link></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-3 col-12 mb-3 mb-md-0">
                            <div class="card-body vueCard sitemap p-3 mb-3">
                                <h3>{{$t('sitemap.sitemapcontent25')}} </h3>
                                <ul class="m-0 p-0">
                                    <!-- <li class="pt-1 mb-2"><router-link to="/become-an-affiliate" class="medium f-14 mb-0">{{$t('sitemap.sitemapcontent26')}} </router-link></li> -->
                                    <!-- <li class="pt-1 mb-2"><router-link to="/affiliate-guide" class="medium f-14 mb-0">{{$t('sitemap.sitemapcontent27')}} </router-link></li> -->
                                    <li class="pt-1 mb-2"><router-link to="/introducing-zulutrade" class="medium f-14 mb-0">{{$t('sitemap.sitemapcontent28')}} </router-link></li>
                                    <li class="pt-1 mb-2"><router-link to="/zuluguard-guide" class="medium f-14 mb-0">{{$t('sitemap.sitemapcontent29')}} </router-link></li>
                                    <li class="pt-1 mb-2"><router-link to="/trading-tools" class="medium f-14 mb-0">{{$t('sitemap.sitemapcontent30')}} </router-link></li>
                                    <li class="pt-1 mb-2"><router-link to="/leader-guide" class="medium f-14 mb-0">{{$t('sitemap.sitemapcontent31')}} </router-link></li>
                                    <li class="pt-1 mb-2"><router-link to="/become-a-leader" class="medium f-14 mb-0">{{$t('sitemap.sitemapcontent32')}} </router-link></li>
                                    <li class="pt-1 mb-2"><router-link to="/partnership-program" class="medium f-14 mb-0">{{$t('sitemap.sitemapcontent33')}} </router-link></li>
                                    <li class="pt-1 mb-2"><router-link to="/user-guide" class="medium f-14 mb-0">{{$t('sitemap.sitemapcontent34')}}  </router-link></li>
                                </ul>
                            </div>
                            <div class="card-body vueCard sitemap p-3">
                                <h3>{{$t('sitemap.sitemapcontent35')}} </h3>
                                <ul class="m-0 p-0">
                                    <li class="pt-1 mb-2"><router-link to="/simulation" class="medium f-14 mb-0">{{$t('sitemap.sitemapcontent36')}} </router-link></li>
                                    <li class="pt-1 mb-2"><router-link to="/compare" class="medium f-14 mb-0">{{$t('sitemap.sitemapcontent37')}} </router-link></li>
                                    <li class="pt-1 mb-2"><router-link to="/social-feed" class="medium f-14 mb-0">{{$t('sitemap.sitemapcontent38')}} </router-link></li>
                                    <!-- <li class="pt-1 mb-2"><router-link to="/newsfeed/share" class="medium f-14 mb-0">{{$t('sitemap.sitemapcontent39')}} </router-link></li> -->
                                    <li class="pt-1 mb-2"><router-link to="/news-and-media" class="medium f-14 mb-0">{{$t('sitemap.sitemapcontent40')}} </router-link></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-3 col-12 mb-3 mb-md-0">
                            <div class="card-body vueCard sitemap p-3">
                                <h3>{{$t('sitemap.sitemapcontent41')}} </h3>
                                <ul class="m-0 p-0">
                                    <li class="pt-1 mb-2"><router-link to="/privacy-policy" class="medium f-14 mb-0">{{$t('sitemap.sitemapcontent42')}} </router-link></li>
                                    <li class="pt-1 mb-2"><router-link to="/zuluranking" class="medium f-14 mb-0">{{$t('sitemap.sitemapcontent43')}} </router-link></li>
                                    <!-- <li class="pt-1 mb-2"><router-link to="/risk-disclaimer" class="medium f-14 mb-0">{{$t('sitemap.sitemapcontent44')}} </router-link></li> -->
                                    <!-- <li class="pt-1 mb-2"><router-link to="/open-new-investror-account" class="medium f-14 mb-0">{{$t('sitemap.sitemapcontent45')}} </router-link></li> -->
                                    <!-- <li class="pt-1 mb-2"><router-link to="/select-broker" class="medium f-14 mb-0">{{$t('sitemap.sitemapcontent46')}} </router-link></li> -->
                                    <li class="pt-1 mb-2"><router-link to="/social-trading" class="medium f-14 mb-0">{{$t('sitemap.sitemapcontent47')}} </router-link></li>
                                    <li class="pt-1 mb-2"><router-link to="/help-center-questions" class="medium f-14 mb-0">{{$t('sitemap.sitemapcontent48')}} </router-link></li>
                                    <li class="pt-1 mb-2"><router-link to="/terms-of-service" class="medium f-14 mb-0">{{$t('sitemap.sitemapcontent49')}} </router-link></li>
                                    <!-- <li class="pt-1 mb-2"><router-link to="/s-of-sertermvice-eu" class="medium f-14 mb-0">{{$t('sitemap.sitemapcontent63')}} </router-link></li> -->
                                    <li class="pt-1 mb-2"><router-link to="/conflict-of-interest-policy" class="medium f-14 mb-0">{{$t('sitemap.sitemapcontent50')}} </router-link></li>
                                    <li class="pt-1 mb-2"><router-link to="/declaration-of-consent" class="medium f-14 mb-0">{{$t('sitemap.sitemapcontent51')}}</router-link></li>
                                    <!-- <li class="pt-1 mb-2"><router-link to="/disclaimer" class="medium f-14 mb-0">{{$t('sitemap.sitemapcontent52')}} </router-link></li> -->
                                    <li class="pt-1 mb-2"><router-link to="/eula" class="medium f-14 mb-0">{{$t('sitemap.sitemapcontent59')}} </router-link></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-3 col-12 mb-3 mb-md-0">
                            <div class="card-body vueCard sitemap p-3">
                                <h3>{{$t('sitemap.sitemapcontent53')}} </h3>
                                <ul class="m-0 p-0">
                                    <li class="pt-1 mb-2"> <a class="d-block medium f-14 mb-0 ps-0" href="https://www.facebook.com/zulutrade/" target="_blank">{{$t('sitemap.sitemapcontent54')}} </a></li>
                                    <li class="pt-1 mb-2"><a class="d-block medium f-14 mb-0" href="https://twitter.com/ZuluTrade" target="_blank">{{$t('sitemap.sitemapcontent55')}} </a></li>
                                    <li class="pt-1 mb-2"> <a class="d-block medium f-14 mb-0" href="https://www.linkedin.com/company/zulutrade" target="_blank">{{$t('sitemap.sitemapcontent56')}} </a></li>
                                    <li class="pt-1 mb-2"> <a class="d-block medium f-14 mb-0" href="https://www.youtube.com/user/zulutradechannel" target="_blank">{{$t('sitemap.sitemapcontent57')}} </a></li>
                                    <li class="pt-1 mb-2"> <a class="d-block medium f-14 mb-0" href="https://www.instagram.com/zulutrade/?hl=en" target="_blank">{{$t('sitemap.sitemapcontent58')}} </a></li>
                                    <li class="pt-1 mb-2"> <a class="d-block medium f-14 mb-0" href="https://www.tiktok.com/@zulutrade" target="_blank">{{$t('sitemap.sitemapcontent60')}} </a></li>
                                    <li class="pt-1 mb-2"> <a class="d-block medium f-14 mb-0" href="https://www.reddit.com/r/Zulu_Trade/" target="_blank">{{$t('sitemap.sitemapcontent61')}} </a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

